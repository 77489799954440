import React from 'react';

interface HeroImageProps {
  image: string;
  height: number;
}

export const HeroImage = ({image, height}: HeroImageProps) => {

  const styles: any = {
    hero: {
      backgroundImage: `url(${image})`,
      maxHeight: height,
      position: 'relative',
      height: height,
      overflow: 'hidden',
      width: '100%'
    },
    hero_image: {
      backgroundImage: `url(${image})`,
      //backgroundSize: 'contain',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      height: '100%',
      width: '100%'
    },
    hero_image_blurred: {
      backgroundImage: `url(${image})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      height: 'calc(100% + 40px)',
      filter: 'blur(10px)',
      left: '-20px',
      position: 'absolute',
      top: '-20px',
      width: 'calc(100% + 40px)',
      zIndex: '-1'
    }
  }

  return (
    <div
      style={styles.hero}
    >
      <div style={styles.hero_image_blurred}></div>
      <div style={styles.hero_image}></div>
    </div>
  );
}
