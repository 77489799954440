import api from './configs/axiosConfig';

export const ResourceAPI = {
  resource: (path: string): Promise<any> => api.getWithType(`/resource/${path}`, 'blob'),
  resourceUrl: (path: string): Promise<string> => {
    return api.getWithType(`/resource${path}`, 'blob').then((response) => {
      const blob = response.data;
      const url = URL.createObjectURL(blob);
      return Promise.resolve(url)
    })
  },
};
