import {useEffect, useState} from "react";

import {trackPromise} from "react-promise-tracker";

import {ResourceAPI} from "apis/ResourceAPI";
import {LoadingSpinner} from "components/LoadingSpinner";

export const ImageResource = (props: any) => {
  const [imageFile, setImageFile] = useState('')

  const {src, ...rest} = props

  useEffect(() => {
    const fetchData = (src: string) => {
      trackPromise(
        ResourceAPI.resourceUrl(src).then((url) => {
          setImageFile(url)
        }), 'imgloader'
      )
    };

    fetchData(src);
  }, [src]);

  return (
    <>
      <LoadingSpinner area="imgloader"/>
      {imageFile && <img src={imageFile} {...rest}/>}
    </>
  )
}
