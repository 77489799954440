import styled from 'styled-components';

import {Breakpoint} from "themes/breakpoints";
import {Colors, Sizes} from 'themes/constants';

/* Common styles */
export const StyledButton = styled.button`
  font-size: ${Sizes.textButton};
  font-weight: bolder;
  margin: 0;
  padding: 0.25em 1em;
  border: none;
  border-radius: 0px;
  color: #fff;
  background-color: ${Colors.orange};
  &:hover {
    background-color: ${Colors.green};
  }
`;

export const StyledTitleBig = styled.div`
  font-size: ${Sizes.titleBig};
  color: ${Colors.orange};
  font-weight: bolder;
  margin: 0;
  padding: 0.25em 1em;
  @media ${Breakpoint.mobile} {
    font-size: 1em;
  }
`;

export const StyledTitleMedium = styled(StyledTitleBig)`
  font-size: ${Sizes.titleMedium};
  margin-bottom: 10px;
  padding: 0;
`;

export const StyledSubTitle = styled.div`
  font-weight: bolder;
`;
