import React, {Fragment} from 'react';

import {Col, Container, Row} from 'react-bootstrap';
import {NavLink} from 'react-router-dom';

import {HeroImage} from 'components/HeroImage';
import {ImageBlock} from 'components/ImageBlock';
import {StyledButton, StyledTitleMedium, StyledSubTitle} from 'themes/CommonStyles';

import {Team} from './Team';

export const Autori = () => {
  return (
    <Fragment>
      <HeroImage image="/images/autori.png" height={500}/>

      <Container fluid style={{padding: '40px'}}>
        <Row className="justify-content-md-center">
          <Col xs={12} md={5}>
            <StyledTitleMedium>CHI SIAMO?</StyledTitleMedium>

            <p>
            Siamo Camilla e Andrea, giriamo l’Italia in lungo e in largo e nel nostro cammino abbiamo incontrato tante persone, anche in luoghi piccoli e insoliti, capaci di trasmettere i loro vissuti, le storie ricevute dai loro antenati e la storia della propria terra.
            </p>
            <p>
            Tramandare è anche un tributo a questo popolo narrante, a questi custodi senza i quali molti luoghi sarebbero chiusi e dimenticati, molte storie relegate ad un prezioso quanto inascoltato silenzio.
            </p>
            <p>
            Il viaggio ha quasi sempre una partenza e un ritorno, un inizio, una fine, la ricerca non si ferma e noi siamo ancora in cammino.
            </p>
          </Col>
          <Col xs={12} md={5}>
            <StyledTitleMedium>I NOSTRI OBIETTIVI</StyledTitleMedium>
            <p>
            Tramandare ha l’obiettivo di evitare la perdita di un pezzo vitale della nostra storia, di perdere l’anello di congiunzione tra il passato e la modernità: anello senza il quale ogni commemorazione, rappresentazione, rievocazione, rischia di cadere nella fiera banale dei gesti vuoti.
            </p>
            <p>
            Seguendo la logica imperfetta della tradizione orale, contaminata nel passaggio di bocca in bocca e da una generazione all’altra, Tramandare raccoglie i racconti delle persone attraverso una registrazione artigianale in presa diretta, proprio per risaltare il valore autentico ed intrinseco della narrazione spontanea: connubio di parola, respiri, gesti, silenzi. Così i racconti prendono la forma del documentario vocale e sonoro.
            </p>

            <NavLink to={'/sostieni'}><StyledButton>SOSTIENI TRAMANDARE</StyledButton></NavLink>

          </Col>
        </Row>
      </Container>

      <HeroImage image="/images/background-04.png" height={400}/>
      <ImageBlock background="/images/background-02.png" height={120}/>

      <Container fluid style={{padding: '40px'}}>
        <Row className="justify-content-md-center">
          <Col xs={12} md={10}>
            <StyledTitleMedium>TRAME, LEGAMI, PERSONE</StyledTitleMedium>

            Tramandare è un progetto fatto di trame, legami e persone, ognuno ha fatto la propria parte in questo
            progetto.
            <br/>
            <strong>Perché senza le persone e le loro esperienze, questo progetto non può esistere.</strong>
          </Col>
        </Row>
      </Container>

      <Team/>
    </Fragment>
  )
}
