import {Card, Container} from 'react-bootstrap';
import styled from 'styled-components';

export const TeamContainerStyled = styled(Container)`
  padding: 40px;
  align-items: center;
  text-align: center;
`;

export const TeamCardStyled = styled(Card)`
  width: 15em;
  border: none;
`;

export const TeamCardTitleStyled = styled(Card.Title)`
  text-align: center;
  font-size: 0.9rem;
  font-weight: bolder;
`;

export const TeamCardSubtitleStyled = styled(Card.Subtitle)`
  text-align: center;
  font-size: 0.8rem;
  font-style: italic;
  margin-bottom: 1vh;
`;
