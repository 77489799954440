import React, {useEffect, useState} from 'react';

import {Card, Col, Container, Row} from 'react-bootstrap';
import {trackPromise} from 'react-promise-tracker';
import {NavLink, useParams} from 'react-router-dom';

import {AudioAPI} from "apis/AudioAPI";
import {LocalitaAPI} from 'apis/LocalitaAPI';
import {ResourceAPI} from "apis/ResourceAPI";
import {HeroImage} from "components/HeroImage";
import {ImageResource} from 'components/ImageResource';
import {LoadingSpinner} from 'components/LoadingSpinner';
import {TramandareFooter} from 'components/TramandareFooter';
import {Audio} from "models/Audio";
import {Localita} from 'models/Localita';
import {AudioCardStyled} from "pages/Audiodoc/LocalitaHome.styles";
import {StyledTitleBig, StyledTitleMedium} from 'themes/CommonStyles';
import {Colors} from 'themes/constants';

export const AudioItem = ({itm, idx}: any) => {
  return (
    <AudioCardStyled className="md-auto">
      <NavLink to={"/audiodoc/audio/" + itm.id}>
        <Card.Body>
          <Card.Title><StyledTitleMedium>{itm.titolo}</StyledTitleMedium></Card.Title>
          <Card.Subtitle className="voce">Voce: {itm.voce} </Card.Subtitle>
          <Card.Subtitle className="descrizione">{itm.descrizione}</Card.Subtitle>
        </Card.Body>
      </NavLink>
    </AudioCardStyled>
  )
}

export const LocalitaHome = () => {
  const params = useParams();
  const localitaId: number = Number(params.localita) | 0;

  const [localita, setLocalita] = useState<Localita>();
  const [audios, setAudios] = useState<Audio[]>();
  const [headerImage, setHeaderImage] = useState<any>();

  useEffect(() => {
    const fetchData = (localitaId: number) => {
      trackPromise(
        LocalitaAPI.get(localitaId).then((localita: Localita) => {
          setLocalita(localita);
          ResourceAPI.resourceUrl('/pictures/' + localita.provincia?.regione?.codice + '/regione_header.png').then((url) => {
            setHeaderImage(url)
          })
        }), 'localita'
      );

      trackPromise(
        AudioAPI.listByLocalita(localitaId).then((audios: Audio[]) => {
          setAudios(audios);
        }), 'audios'
      )
    }

    fetchData(localitaId);
  }, [localitaId]);

  return (
    <>
      <LoadingSpinner area="localita"/>

      {localita && (
        <>
          <HeroImage image={headerImage} height={500}/>

          <Container fluid style={{padding: '2rem'}}>
            <Row style={{textAlign: 'center'}} className="justify-content-center align-items-center">
              <Col xs={12} md={4} style={{textAlign: 'right'}}>
                <NavLink to={'/audiodoc/regione/' + localita?.provincia?.regione?.id}>
                  <ImageResource src={'/pictures/' + localita?.provincia?.regione?.codice + '/regione.png'}
                                 alt={localita?.provincia?.regione?.denominazione} height={100}/>
                </NavLink>
              </Col>
              <Col xs={12} md={4} style={{textAlign: 'left'}}>
                <StyledTitleBig>{localita?.denominazione?.toUpperCase()} ({localita?.provincia?.sigla?.toUpperCase()})</StyledTitleBig>
              </Col>
            </Row>
          </Container>

          <Container fluid style={{backgroundColor: Colors.lightBackground}}>
            <Row style={{textAlign: 'center'}}>
              <Col>
                <ImageResource
                  src={'/pictures/' + localita?.provincia?.regione?.codice + '/paesi/' + localita?.imageFilename}
                  alt={localita?.provincia?.regione?.denominazione}/>
              </Col>
            </Row>
          </Container>

          <Container fluid style={{padding: '40px'}}>
            <Row style={{textAlign: 'center'}} className="justify-content-center">
              <LoadingSpinner area="audios"/>
              {audios?.map((itm, idx) => {
                return (
                  <Col key={idx} xs="auto" md="auto">
                    <AudioItem itm={itm} idx={idx}/>
                  </Col>
                )
              })}
            </Row>
          </Container>
        </>)
      }
      <TramandareFooter/>
    </>
  )
}
