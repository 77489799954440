import React from 'react';

import {HeroImage} from 'components/HeroImage';
import {ImageBlock} from 'components/ImageBlock';

export const Link = () => {
  return (
    <React.Fragment>
      <HeroImage image="../images/audiodoc.png" height={500}/>

      <ImageBlock background="../images/background-02.png" height={120}/>

    </React.Fragment>
  )
}
