import React, {Fragment} from 'react'

import {Outlet} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {Footer} from 'components/Footer';
import {NavigationBar} from 'components/Navbar';
import {SEO} from 'components/SEO';

export const Layout = () => {
  return (
    <Fragment>
      <SEO
        title="Tramandare"
        description="Il sito di Tramandare"
      />

      <NavigationBar/>

      <main style={{padding: '0rem 0'}}>
        <Outlet/>
      </main>

      <Footer/>

      <ToastContainer/>
    </Fragment>
  );
};
