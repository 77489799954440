import React from 'react';

import {Col, Container, Row} from 'react-bootstrap';
import {NavLink} from 'react-router-dom';

import {AudioBox} from 'components/AudioBox';
import {HeroImage} from 'components/HeroImage';
import {ImageBlock} from 'components/ImageBlock';
import {StyledButton, StyledSubTitle, StyledTitleMedium} from 'themes/CommonStyles';
import {Colors} from 'themes/constants';

export const Ideazione = () => {
  return (
    <React.Fragment>

      <HeroImage image="/images/ideazione.png" height={450}/>

      <Container fluid style={{padding: '40px'}}>
        <Row className="justify-content-md-center">
          <Col xs={12} md={12}>
            <p>Tramandare è un “moto a luogo” fisico ed interattivo nei luoghi dove è ancora feconda la memoria che lega
              l’uomo alle sue antiche origini.</p>
            <p>Un recupero della memoria e delle voci capaci di viaggiare tra leggenda e realtà sul filo teso dalla
              storia; ma il senso del viaggio è anche l’incontro, l’intreccio con il nuovo che muove i territori ed è
              ancora capace di raccontarsi e produrre nuova linfa, nuove storie.</p>
            <p>Attraverso l’archiviazione e la riscoperta dell’oralità, Tramandare nasce con l’idea di stimolare e
              risvegliare dal torpore l’immaginazione e la sensibilità che oggigiorno paiono sopite e, in taluni casi,
              dimenticate.</p>
            <p>Tutto il materiale raccolto viene coagulato, in formato sonoro, nel portale Tramandare, che rappresenta
              un vero e proprio archivio dei saperi: voci, suoni e rumori dell’ambiente, saranno la struttura portante
              dei racconti.</p>
            <p>In un mondo sempre più bombardato da immagini e saturo di freddi dati, l’essenza di questo lavoro è
              quello di liberare l’emotività del racconto attraverso il suo vibrare sonoro.</p>

          </Col>
        </Row>
      </Container>

      <ImageBlock background="/images/background-02.png" height={121}/>

      <AudioBox
        text={<>Quanto segue è un “dietro le quinte” registrato ad Andrista, in alta Val Camonica. È un breve estratto
          di un dialogo registrato durante il racconto del Badalisc con la tipica maschera del Nonno in cui è possibile
          scorgere l’essenza di Tramandare. Questa sarà l’unica volta in cui si sentiranno le nostre voci che, nel
          seguito degli audio documentari, scompariranno, per lasciare posto al racconto originario dei luoghi e alla
          spontaneità delle testimonianze. Crediamo che più di mille parole, intenzioni, obiettivi, questo estratto
          sintetizzi al meglio la nostra ricerca e, fedeli all’audio registrazione, preferiamo presentarci così.</>}
        audio="/audio/tramandare_backstage.mp3"/>

      <Container fluid style={{backgroundColor: Colors.lightBackground}}>
        <Row className="justify-content-md-center">
          <Col xs={12} md={10} style={{textAlign: 'center', marginBottom: '40px'}}>
            <NavLink to={'/sostieni'}><StyledButton>SOSTIENI TRAMANDARE</StyledButton></NavLink>
          </Col>
        </Row>
      </Container>

      <Container fluid style={{padding: '40px'}}>
        <Row className="justify-content-md-center">
          <Col xs={12} md={5}>
            <img src="/images/ideazione-01.png" width={180} alt="" className="responsive"/>
          </Col>
          <Col xs={12} md={5}>
            <StyledTitleMedium>VERBA MANENT</StyledTitleMedium>

            Tramandare ha l’obiettivo di evitare la perdita di un pezzo vitale della nostra storia, di perdere l’anello
            di congiunzione tra il passato e la modernità: anello senza il quale ogni commemorazione, rappresentazione,
            rievocazione rischia di cadere nella fiera banale dei gesti vuoti.
            <br/>
            <br/>
            Seguendo la logica imperfetta della tradizione orale, contaminata nel passaggio di bocca in bocca e da una
            generazione all’altra,
            <br/>
            <br/>

            <StyledSubTitle>TRAMANDARE RACCOGLIE I RACCONTI DELLE PERSONE ATTRAVERSO UNA REGISTRAZIONE ARTIGIANALE IN
              PRESA DIRETTA, PROPRIO PER RISALTARE IL VALORE AUTENTICO ED INTRINSECO DELLA NARRAZIONE
              SPONTANEA.</StyledSubTitle>
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col xs={12} md={10} style={{textAlign: 'center', marginTop: '20px'}}>
            <StyledTitleMedium>CONNUBIO DI PAROLA, RESPIRI, GESTI E SILENZI.<br/> COSÌ I RACCONTI PRENDONO LA FORMA DEL
              DOCUMENTARIO VOCALE E SONORO.</StyledTitleMedium>

            <NavLink to={'/audiodoc'}><StyledButton>VAI AGLI AUDIODOC</StyledButton></NavLink>
          </Col>
        </Row>
      </Container>


    </React.Fragment>
  )
}
