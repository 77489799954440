import {Card, Container} from "react-bootstrap";
import styled from "styled-components";

export const AudioCardStyled = styled(Card)`
  margin: 10px;
  width: 18rem;
  padding: 0;
  border-radius: 0;
  --bs-card-title-spacer-y: 0;

  .voce {
    font-style: italic;
    font-size: 0.8em;
    padding-bottom: 1vh;
  }

  .descrizione {
    font-style: italic;
    font-size: 0.7em;
  }

  a {
    color: black;
    text-decoration: none;
  }
`;

export const AudioContainerStyled = styled(Container)`
  padding: 40px;
  align-items: center;
  text-align: center;
`;
