import React from 'react';

import {HelmetProvider} from 'react-helmet-async';
import {Route, Routes} from 'react-router-dom';

import {AdminLayout} from 'layouts/AdminLayout';
import {Layout} from 'layouts/Layout';
import {About} from 'pages/About';
import {Audiodoc, SelectLocalita, AudioHome, LocalitaHome} from 'pages/Audiodoc';
import {Autori} from 'pages/Autori';
import {Contatti} from 'pages/Contatti';
import {Diario} from 'pages/Diario';
import {NotFound} from 'pages/Errors';
import {Home} from 'pages/Home';
import {Ideazione} from 'pages/Ideazione';
import {Link} from 'pages/Link';
import {Sostieni} from 'pages/Sostieni';

import './App.css';

function App() {
  const helmetContext = {};

  return (
    <>
      <HelmetProvider context={helmetContext}>
        <Routes>
          <Route element={<Layout/>}>
            <Route index element={<Home/>}/>
            <Route path="/ideazione" element={<Ideazione/>}/>
            <Route path="/audiodoc" element={<Audiodoc/>}/>
            <Route path="/autori" element={<Autori/>}/>
            <Route path="/diario" element={<Diario/>}/>
            <Route path="/link" element={<Link/>}/>
            <Route path="/contatti" element={<Contatti/>}/>

            <Route path="/sostieni" element={<Sostieni/>}/>

            <Route path="/audiodoc/regione/:regione" element={<SelectLocalita/>}/>
            <Route path="/audiodoc/localita/:localita" element={<LocalitaHome/>}/>
            <Route path="/audiodoc/audio/:audio" element={<AudioHome/>}/>

            <Route path="*" element={<NotFound/>}/>
          </Route>
          <Route path="/admin" element={<AdminLayout/>}>
            <Route path="/admin/home" element={<About/>}/>
          </Route>
        </Routes>
      </HelmetProvider>
    </>
  );
}

export default App;
