import React, {Fragment} from 'react';

import {Col, Row} from 'react-bootstrap';

import {ContainerStyled, RowCopyrightStyled, ColLeftStyled, ColRightStyled} from 'components/Footer/Footer.styles';

import logo from 'images/logo-tramandare-footer.png';

export const Footer = () => {
  return (
    <Fragment>
      <ContainerStyled fluid>
        <Row>
          <ColLeftStyled>
            <img src={logo} alt="logo" style={{height: '30px', textAlign: 'right'}}/>
            <br/>
            <br/>
            È un progetto a cura di<br/>
            Andrea Liberatore<br/>
            e Camilla Zoppis
          </ColLeftStyled>
          <ColRightStyled>
            Seguici su <b>Facebook</b><br/>
            e su <b>Instagram</b>,<br/>
            condividi e tramanda<br/>
            insieme a noi!
          </ColRightStyled>
        </Row>
        <RowCopyrightStyled>
          <Col xs={12} sm={12}>
            Tutti i contenuti multimediali (testi, foto, video, audio ecc ecc) presenti in questo sito sono originali e
            protetti da copyright Tramandare. È pertanto vietata l'appropriazione o qualsiasi riproduzione non
            autorizzata degli stessi.
          </Col>
        </RowCopyrightStyled>
      </ContainerStyled>
    </Fragment>
  );
}
