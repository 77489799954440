import {Container, Row, Col} from 'react-bootstrap';
import styled from 'styled-components';

import {Colors} from 'themes/constants';

const minimumWidth = '287px';

export const ContainerStyled = styled(Container)`
  background-color: ${Colors.lightBackground};
  font-size: 0.7em;
  padding-top:2vh;
`;

export const ColLeftStyled = styled(Col)`
  border-right: 1px solid ${Colors.orange};
  padding: 2vh;
  text-align: right;

  @media (max-width: ${minimumWidth}) {
    text-align: center;
    display: block;
    border: none;
  }
`;

export const ColRightStyled = styled(Col)`
  padding: 2vh;
  text-align: left;

  @media (max-width: ${minimumWidth}) {
    padding-top: 0;
    text-align: center;
  }
`;

export const RowCopyrightStyled = styled(Row)`
  font-size: 0.7rem;
  justify-content: center;
  text-align: center;
  padding-top: 2vh;
  padding-bottom: 2vh;
`;
