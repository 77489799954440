import React, {Fragment} from 'react';

import {Card, Col, Row} from 'react-bootstrap';

import {TeamContainerStyled, TeamCardStyled, TeamCardTitleStyled, TeamCardSubtitleStyled} from './Team.styles';

interface Member {
  name?: string;
  description?: string;
  image?: string;
}

const teamMembers: Member[] = [
  {
    name: 'PINCO PALLINO',
    description: 'Narratore, storyteller instancabile',
    image: '1.png'
  },
  {
    name: 'TIZIO CAIO',
    description: 'Sviluppatore, pragmatico, informatico',
    image: '2.png'
  },
  {
    name: 'MARIO ROSSI',
    description: 'Grafico, esteta, idealista, illustratore',
    image: '3.png'
  },
  {
    name: 'PAOLO BIANCHI',
    description: 'Videomaker, cercatore di storie, curiosone',
    image: '4.png'
  }
]

interface TeamItemProps {
  member: Member;
}

const TeamItem = ({member}: TeamItemProps) => {
  return (
    <TeamCardStyled className="md-auto">
      <Card.Body>
        <TeamCardTitleStyled>{member.name}</TeamCardTitleStyled>
        <TeamCardSubtitleStyled>{member.description}</TeamCardSubtitleStyled>
        <Card.Img src={'/images/team/' + member.image}/>
      </Card.Body>
    </TeamCardStyled>
  )
}

export const Team = () => {
  return (
    <Fragment>
      <TeamContainerStyled fluid>
        <Row style={{textAlign: 'center'}} className="justify-content-center">
          {teamMembers.map((member, idx) => {
            return (
              <Col key={idx} xs="auto" md="auto">
                <TeamItem member={member}/>
              </Col>
            )
          })}
        </Row>
      </TeamContainerStyled>
    </Fragment>
  )
}
