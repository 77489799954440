import {Audio} from 'models/Audio';

import api from './configs/axiosConfig';

export const AudioAPI = {
  get: (id: number): Promise<Audio> => api.get(`/audiodoc/audio/${id}`),
  list: (): Promise<Array<Audio>> => api.get(`/audiodoc/audio/list`),
  listByLocalita: (localitaId: number): Promise<Array<Audio>> => api.get(`/audiodoc/audio/localita/${localitaId}/list`),
  listByProvincia: (provincia: string | undefined): Promise<Array<Audio>> => api.get(`/audiodoc/audio/provincia/${provincia}/list`),
  listByRegione: (regione: number): Promise<Array<Audio>> => api.get(`/audiodoc/audio/regione/${regione}/list`),

  /*
  getAPost: (id: number): Promise<PostType> => requests.get(`posts/${id}`),
  createPost: (post: PostType): Promise<PostType> =>
    requests.post('posts', post),
  updatePost: (post: PostType, id: number): Promise<PostType> =>
    requests.put(`posts/${id}`, post),
  deletePost: (id: number): Promise<void> => requests.delete(`posts/${id}`),
   */
};
