import React from 'react';

import {ThreeDots} from 'react-loader-spinner';
import {usePromiseTracker} from 'react-promise-tracker';

import {Colors} from 'themes/constants';

export const LoadingSpinner = (props: any) => {
  const {promiseInProgress} = usePromiseTracker({area: props.area, delay: 500});

  return (
    <>
      {
        promiseInProgress && (
          <div
            style={{
              width: '100%',
              height: '100',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <ThreeDots
              height="80"
              width="80"
              radius="9"
              color={Colors.orange}
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              visible={true}
            />
          </div>
        )
      }
    </>
  );
}

