import React from 'react'

import {Outlet} from 'react-router-dom';

export const AdminLayout = () => {
  return (
    <React.Fragment>
      <h1>ADMIN</h1>

      <main style={{padding: '1rem 0'}}>
        <Outlet/>
      </main>
    </React.Fragment>
  );
};
