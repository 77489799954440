import React, {useEffect, useState} from 'react'

import {Container, Row, Col} from 'react-bootstrap';
import {trackPromise, usePromiseTracker} from 'react-promise-tracker';
import {NavLink, useParams} from 'react-router-dom';

import {LocalitaAPI} from 'apis/LocalitaAPI';
import {ProvinciaAPI} from 'apis/ProvinciaAPI';
import {RegioneAPI} from 'apis/RegioneAPI';
import {ResourceAPI} from "apis/ResourceAPI";
import {HeroImage} from 'components/HeroImage';
import {ImageBlock} from 'components/ImageBlock';
import {LoadingSpinner} from 'components/LoadingSpinner';
import {TramandareFooter} from 'components/TramandareFooter';
import {Localita} from 'models/Localita';
import {Provincia} from 'models/Provincia';
import {Regione} from 'models/Regione';
import {StyledTitleMedium} from 'themes/CommonStyles';
import {Colors} from 'themes/constants';
import Utils from 'utils/Utils';

import {
  LocalitaCardStyled,
  LocalitaCardTitleStyled,
  LocalitaCardBodyStyled,
  LocalitaCardImgStyled
} from './Audiodoc.styles';

interface LocalitaItemProps {
  localita: Localita;
}

const LocalitaItem = ({localita}: LocalitaItemProps) => {
  const [image, setImage] = useState('');

  useEffect(() => {
    const fetchImage = (localita: Localita) => {
      const regioneId = localita.provincia?.regione?.id;
      const regioneCode = localita.provincia?.regione?.codice;
      const localitaId = localita.id;
      const localitaImage = localita.imageFilename;
      const path = '/pictures/' + regioneCode + '/paesi/' + localitaImage;
      trackPromise(
        ResourceAPI.resourceUrl(path).then((url) => {
          setImage(url);
        }), 'image'
      )
    };

    fetchImage(localita);
  }, [localita])

  return (
    <LocalitaCardStyled className="mx-auto">
      <LoadingSpinner area="image"/>
      <NavLink to={'/audiodoc/localita/' + localita.id}>
        <LocalitaCardImgStyled variant="top" src={image}/>
        <LocalitaCardBodyStyled>
          <LocalitaCardTitleStyled>
            {localita.denominazione} ({localita?.provincia?.sigla?.toUpperCase()})
          </LocalitaCardTitleStyled>
        </LocalitaCardBodyStyled>
      </NavLink>
    </LocalitaCardStyled>
  )
}

interface LocalitaAudiosListProps {
  provincia: Provincia;
}

const LocalitaAudiosList = ({provincia}: LocalitaAudiosListProps) => {
  const [localita, setLocalita] = useState<Localita[]>()

  useEffect(() => {
    const fetchData = (provinciaId: number) => {
      trackPromise(
        LocalitaAPI.listWithAudio(provinciaId).then((localita: Localita[]) => {
          setLocalita(localita);
        }).catch((error) => {
          Utils.connectionError();
        }), 'localita'
      );
    }

    fetchData(provincia?.id);
  }, [provincia?.id]);

  return (
    <>
      <LoadingSpinner area="localita"/>

      <Container fluid className="justify-content-center">
        <Row>
          {localita?.map((l, i) => {
            return (
              <Col key={i}>
                <LocalitaItem localita={l}/>
              </Col>
            )
          })}
        </Row>
      </Container>
    </>
  );
}

interface ProvinciaAudiosListProps {
  idx: number;
  provincia: Provincia;
}

const ProvinciaAudiosList = ({provincia, idx}: ProvinciaAudiosListProps) => {
  return (
    <>
      <div style={{
        textAlign: 'center',
        color: '#fff',
        backgroundColor: idx % 2 === 0 ? Colors.lightBackground : Colors.white
      }}>
        <span style={{
          fontSize: '2em',
          color: Colors.orange
        }}>PROVINCIA DI {provincia?.denominazione?.toUpperCase()}</span>

        <ul style={{
          listStyleType: 'none',
          fontSize: '13pt',
          color: Colors.black
        }}>
          {/* Elenco di località per la provincia che hanno audio */}
          <LocalitaAudiosList provincia={provincia}/>
        </ul>
      </div>
    </>
  )
}

export const SelectLocalita = () => {
  const {promiseInProgress: loading} = usePromiseTracker({area: 'regione', delay: 500});

  const [image, setImage] = useState<any>();
  const [regione, setRegione] = useState<Regione>();
  const [province, setProvince] = useState<Provincia[]>([]);

  const params = useParams();
  const regioneId: number = Number(params.regione) | 0;

  useEffect(() => {
    const fetchData = (regioneId: number) => {
      trackPromise(
        RegioneAPI.get(regioneId).then((regione: Regione) => {
          setRegione(regione);

          // Get header image
          ResourceAPI.resourceUrl('/pictures/' + regione.codice + '/regione_header.png').then((url) => {
            setImage(url)
          })
        }).catch((error) => {
          Utils.connectionError();
        }), 'regione'
      );

      trackPromise(
        ProvinciaAPI.listWithAudio(regioneId).then((province: Provincia[]) => {
          setProvince(province);
        }).catch((error) => {
          Utils.connectionError();
        }), 'regione'
      );

      /*
      trackPromise(
        // LocalitaAPI.resource('/pictures/' + regioneId + '/regione_header.png').then((response) => {
        ResourceAPI.resourceUrl('/pictures/' + regione.codice + '/regione_header.png').then((url) => {
          setImage(url)
        })
      )
       */
    };

    fetchData(regioneId);
  }, [regioneId]);

  return (
    <>
      <HeroImage image={image} height={500}/>

      <ImageBlock
        background="/images/background-01.png"
        description={<><strong>“Non è la voce che comanda la storia: sono le orecchie”</strong><br/>
          Italo Calvino</>}
      />

      {regione ?
        <div style={{
          fontSize: '3em',
          textAlign: 'center'
        }}>
          <img src={'/pictures/' + regione.codice + '/regione.png'} alt=""/>
        </div>
        :
        !loading && (<StyledTitleMedium>REGIONE NON PRESENTE IN ARCHIVIO</StyledTitleMedium>)
      }

      <LoadingSpinner area="regione"/>

      {!loading && province?.length === 0 ?
        <StyledTitleMedium>Nessun audio per questa regione</StyledTitleMedium> : ''
      }

      {province?.map((p, i) => {
        return (
          <ProvinciaAudiosList key={i} idx={i} provincia={p}/>
        )
      })}

      <TramandareFooter/>
    </>
  )
}
