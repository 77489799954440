import {Nav, Navbar} from 'react-bootstrap';
import {NavLink} from 'react-router-dom';
import styled from 'styled-components';

import {Breakpoint} from 'themes/breakpoints';
import {Colors} from 'themes/constants';

export const NavStyled = styled(Nav)`
`;

export const NavbarStyled = styled(Navbar)`
  background-color: ${Colors.lightBackground};
`;

export const NavItemStyled = styled(Nav.Item)`
  @media ${Breakpoint.mobile} {
    padding: 1rem;
  }
`;

export const NavbarCollapseStyled = styled(Navbar.Offcanvas)`
  @media ${Breakpoint.mobile} {
    padding-top: 5vh;
    text-align: center;
    background-color: ${Colors.lightBackground};
  }
`;

export const NavLinkStyled = styled(NavLink)`
  font-size: 0.9em;
  font-weight: 500;
  color: ${Colors.black};
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;

  &:hover {
    border-top: 2px solid ${Colors.orange};
    color: ${Colors.orange};
  }

  &.active {
    border-top: 2px solid ${Colors.green};
    color: ${Colors.green};
  }

  @media ${Breakpoint.mobile} {
    font-size: 1.2em;

    &:hover {
      border: none;
      color: ${Colors.orange};
    }

    &.active {
      border: none;
      color: ${Colors.green};
    }
  }
`;
