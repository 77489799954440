import React, {Fragment} from 'react'

import {Col, Container, Row} from 'react-bootstrap';
import {NavLink} from 'react-router-dom';

import {AudioBox} from 'components/AudioBox';
import {HeroImage} from 'components/HeroImage';
import {ImageBlock} from 'components/ImageBlock';
import {TramandareFooter} from 'components/TramandareFooter';
import {StyledButton} from 'themes/CommonStyles';

import 'react-h5-audio-player/lib/styles.css';
import {ContainerStyles} from "./Home.styles";

export const Home = () => {
  return (
    <Fragment>
      <HeroImage image="/images/home-10.png" height={500}/>

      <ImageBlock
        background="/images/background-01.png"
        description={<><strong>tramandare</strong> v. tr. [der. di mandare, col pref. tra-]. – 1. Trasmettere nel tempo,<br/> da
          una generazione a un’altra, notizie, fatti, tradizioni, valori spirituali</>}
      />

      <ContainerStyles fluid={true} className="justify-content-md-center" style={{marginTop: '10px', marginBottom: '10px', padding: '40px'}}>
        <Row className="justify-content-md-center">
          <Col xs={12} md={4}>
            <img src="/images/cartina.png" height="50%" className="responsive" alt=""/>
          </Col>
          <Col xs={12} md={6}>
            <p style={{marginTop: '40px'}}>Abbiamo viaggiato in lungo e in largo per l'Italia, visitato luoghi, scoperto spazi e conosciuto persone.
              E proprio dall'amore per l'ascolto e dalla passio- ne per le storie perdute, è nato Tramandare.</p>
            <p>Il nostro viaggio è iniziato nel 20** e da allora non si è mai fermato. Abbiamo seguito il flusso
              naturale
              delle cose, incontrando persone inaspettate e ascoltando storie dal sapore di leggende.</p>
            <p>ABBIAMO SCELTO DI TRAMANDARE QUESTE STORE PER PORTARE AVANTI NEL TEMPO LA LORO MEMORIA BLA BLA BLA...</p>
            <NavLink to={'/diario'}><StyledButton>SCOPRI IL NOSTRO VIAGGIO</StyledButton></NavLink>
          </Col>
        </Row>
      </ContainerStyles>

      <AudioBox
        text={<> "La tradizione non è il frutto né di un passato, né di un futuro, essa è questo tempo profetico<br/>
          che s'immerge nell'atemporale e s'incarna nell'istante."</>}
        signature={'Annick de Souzenelle'}
        audio="/audio/Sora-Nanna-la-dea-dei-morti-voce-di-Marcella-Maiolatesi.mp3"/>

      <TramandareFooter/>

    </Fragment>
  )
}

/* https://react-responsive-carousel.js.org/ */

/* image and text overlay */
/* https://www.upbeatcode.com/react/how-to-write-text-on-image-in-react-js/ */
