import React, {Fragment} from 'react';

import {HeroImage} from 'components/HeroImage';
import {TramandareFooter} from 'components/TramandareFooter';

export const Diario = () => {
  return (
    <Fragment>
      <HeroImage image="/images/audiodoc.png" height={500}/>

      <TramandareFooter/>
    </Fragment>
  )
}
