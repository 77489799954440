import {Card} from 'react-bootstrap';
import styled from 'styled-components';

import {Colors} from 'themes/constants';

export const LocalitaCardStyled = styled(Card)`
  margin: 10px;
  width: 18rem;
  padding: 0;
  border-radius: 0;
  --bs-card-title-spacer-y: 0;

  a {
    color: white;
    text-decoration: none;
  }
`;

export const LocalitaCardImgStyled = styled(Card.Img)`
  border-radius: 0;
`;

export const LocalitaCardBodyStyled = styled(Card.Body)`
  background-color: ${Colors.orange};
  padding: 0.5em;
`;

export const LocalitaCardTitleStyled = styled(Card.Title)`
  color: ${Colors.white};
  padding-top: 2px;
`;
