import React, {useEffect, useState} from 'react';

import {Col, Container, Row} from 'react-bootstrap';
import {trackPromise} from 'react-promise-tracker';
import {NavLink, useParams} from 'react-router-dom';

import {AudioAPI} from "apis/AudioAPI";
import {AudioBox} from 'components/AudioBox';
import {ImageResource} from "components/ImageResource";
import {LoadingSpinner} from 'components/LoadingSpinner';
import {TramandareFooter} from 'components/TramandareFooter';
import {Audio} from "models/Audio";
import {AudioItem} from "pages/Audiodoc/LocalitaHome";
import {AudioContainerStyled} from "pages/Audiodoc/LocalitaHome.styles";
import {StyledTitleBig, StyledTitleMedium} from 'themes/CommonStyles';
import {Colors} from 'themes/constants';

export const AudioHome = () => {
  const params = useParams();
  const audioId: number = Number(params.audio) | 0;

  const [audio, setAudio] = useState<Audio>();
  const [otherAudios, setOtherAudios] = useState<Audio[]>();

  useEffect(() => {
    const fetchData = (audioId: number) => {
      trackPromise(
        AudioAPI.get(audioId).then((audio: Audio) => {
          setAudio(audio);
          if (audio) {
            AudioAPI.listByProvincia(audio?.localita?.provincia?.sigla).then((audios: Audio[]) => {
              setOtherAudios(audios.filter((a) => {
                return a.id !== audio.id
              }));
            })
          }
        }), 'audio'
      );
    }

    fetchData(audioId);
  }, [audioId]);

  return (
    <>
      <Container fluid style={{padding: '2rem'}}>
        <Row style={{textAlign: 'center'}} className="justify-content-center align-items-center">
          <Col xs={12} md={4} style={{textAlign: 'right'}}>
            {audio && <NavLink to={'/audiodoc/regione/' + audio?.localita?.provincia?.regione?.id}>
              <ImageResource src={'/pictures/' + audio?.localita?.provincia?.regione?.codice + '/regione.png'}
                             alt={audio?.localita?.provincia?.regione?.denominazione} height={100}/>
            </NavLink>}
          </Col>
          <Col xs={12} md={4} style={{textAlign: 'left'}}>
            <NavLink
              to={'/audiodoc/localita/' + audio?.localita?.id}><StyledTitleBig>{audio?.localita?.denominazione?.toUpperCase()} ({audio?.localita?.provincia?.sigla})</StyledTitleBig></NavLink>
          </Col>
        </Row>
      </Container>

      <Container fluid style={{backgroundColor: Colors.lightBackground}}>
        <Row style={{textAlign: 'center'}}>
          <Col>
            <StyledTitleBig>{audio?.titolo}</StyledTitleBig>
            <div style={{fontSize: '0.9em', paddingBottom: '1vh'}}>Voce: {audio?.voce}</div>
            <div style={{fontSize: '0.9em'}}>{audio?.descrizione}</div>
          </Col>
        </Row>
        <Row>
          <Col>
            {audio ? <AudioBox
                text={<></>}
                audio={"/audio/" + audio?.audioCode}/>
              :
              <LoadingSpinner area="audio"/>
            }
          </Col>
        </Row>
      </Container>

      {otherAudios?.length! &&
        <AudioContainerStyled fluid>
          <Row style={{textAlign: 'center'}} className="justify-content-center">
            <Col xs="auto" md="auto">
              <StyledTitleMedium>Altri audio della provincia
                di {audio?.localita.provincia?.denominazione}</StyledTitleMedium>
            </Col>
          </Row>
          <Row style={{textAlign: 'center'}} className="justify-content-center">
            {otherAudios?.map((audio: Audio, idx: number) => {
              return (
                <Col key={idx} xs="auto" md="auto">
                  <AudioItem itm={audio} idx={idx}/>
                </Col>
              )
            })}
          </Row>
        </AudioContainerStyled>
      }
      <TramandareFooter/>
    </>
  )
}
