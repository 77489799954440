import {Row} from 'react-bootstrap';
import styled from 'styled-components';

import {Colors, Sizes} from 'themes/constants';

export const Centered = styled.div`
  text-align: center;
`;

export const Title = styled.div`
  color: ${Colors.orange};
  font-size: ${Sizes.titleMedium};
  font-weight: bolder;
  margin-bottom: 10px;
`;

export const SubTitle = styled.div`
  font-weight: bolder;
`;

export const StyledRow = styled(Row)`
  padding: 40px;
`;
