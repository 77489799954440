import styled from 'styled-components';

const Colors = {
  background: '#F8F6ED',
  border: '#242222'
}

export const AudioBoxStyled = styled.div`
  background-color: ${Colors.background};
  padding: 50px;
  text-align: center;
`;

export const AudioBoxDescription = styled.div`
  font-family: "Courier New", Courier, monospace;
  font-weight: bolder;
  text-align: center;
`;

export const AudioBoxSignature = styled.span`
  font-family: "Courier New", Courier, monospace;
  text-align: center;
  font-weight: lighter;
  font-style: italic;
`;

export const AudioBoxPlayer = styled.div`
  height: auto;
  width: 100%;
  margin: 0 auto;
  padding-top: 10px;
  position: relative;
`;

export const AudioBoxPlayerControls = styled.div`
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  border: ${Colors.border} solid 1px;
`;
