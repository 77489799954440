import React, {ReactNode, useEffect, useState} from 'react';

import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import {trackPromise} from "react-promise-tracker";

import {ResourceAPI} from "apis/ResourceAPI";
import {LoadingSpinner} from "components/LoadingSpinner";

import {
  AudioBoxStyled,
  AudioBoxDescription,
  AudioBoxSignature,
  AudioBoxPlayer,
  AudioBoxPlayerControls
} from './AudioBox.styles';

interface AudioBoxProps {
  text?: ReactNode;
  signature?: string;
  audio: string;
}

export const AudioBox = ({text = '', signature = '', audio}: AudioBoxProps) => {
  const [audioFile, setAudioFile] = useState('')

  useEffect(() => {
    const fetchData = (audioPath: string) => {
      trackPromise(
        ResourceAPI.resourceUrl(audioPath).then((url) => {
          setAudioFile(url)
        }), 'audiobox'
      )
    };

    fetchData(audio);
  }, [audio]);

  return (
    <>
      <AudioBoxStyled>
        {text && (
          <AudioBoxDescription>
            {text}
            {signature && (
              <>
                <br/>
                <AudioBoxSignature>{signature}</AudioBoxSignature>
              </>
            )}
          </AudioBoxDescription>
        )}

        <LoadingSpinner area="audiobox"/>

        {audioFile && <AudioBoxPlayer>
          <AudioBoxPlayerControls>
            <AudioPlayer
              src={audioFile}
              autoPlay={false}
            />
          </AudioBoxPlayerControls>
        </AudioBoxPlayer>}
      </AudioBoxStyled>
    </>
  )
}
