import React, {Fragment, useState} from 'react';

import {Container, Navbar, Offcanvas} from 'react-bootstrap';
import {NavLink} from 'react-router-dom';

import {
  NavStyled,
  NavbarStyled,
  NavbarCollapseStyled,
  NavLinkStyled,
  NavItemStyled
} from 'components/Navbar/Navbar.styles';

import logo from 'images/logo-tramandare.png';


export const NavigationBar = () => {
  const [expanded, setExpanded] = useState(false);
  const closeMobileMenu = () => setExpanded(false);

  return (
    <Fragment>
      <NavbarStyled expanded={expanded} sticky="top" className="justify-content-center" expand="lg" variant="light">
        <Container>
          <Navbar.Brand>
            <NavLink to="/"><img src={logo} alt="logo" style={{height: '45px'}}/></NavLink>
          </Navbar.Brand>
          <Navbar.Toggle onClick={() => setExpanded(!expanded)} aria-controls="responsive-navbar-nav"/>
          <NavbarCollapseStyled id="responsive-navbar-nav" placement="end">
            <Offcanvas.Header closeButton onClick={closeMobileMenu}/>
            <Offcanvas.Body>
              <NavStyled className="ms-auto">
                <NavItemStyled>
                  <NavLinkStyled to="/ideazione" onClick={closeMobileMenu}>IDEAZIONE</NavLinkStyled>
                </NavItemStyled>
                <NavItemStyled>
                  <NavLinkStyled to="/audiodoc" onClick={closeMobileMenu}>AUDIODOC</NavLinkStyled>
                </NavItemStyled>
                <NavItemStyled>
                  <NavLinkStyled to="/autori" onClick={closeMobileMenu}>AUTORI</NavLinkStyled>
                </NavItemStyled>
                <NavItemStyled>
                  <NavLinkStyled to="/diario" onClick={closeMobileMenu}>DIARIO DI BORDO</NavLinkStyled>
                </NavItemStyled>
                <NavItemStyled>
                  <NavLinkStyled to="/link" onClick={closeMobileMenu}>LINK</NavLinkStyled>
                </NavItemStyled>
                <NavItemStyled>
                  <NavLinkStyled to="/contatti" onClick={closeMobileMenu}>CONTATTI</NavLinkStyled>
                </NavItemStyled>
              </NavStyled>
            </Offcanvas.Body>
          </NavbarCollapseStyled>
        </Container>
      </NavbarStyled>
    </Fragment>
  )
}

