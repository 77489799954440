export const Colors = {
  white: '#fff',
  black: '#000',
  orange: '#ea644d',
  green: '#2a9d8f',
  lightBackground: '#f8f6ed'
}

export const Sizes = {
  titleBig: '2em',
  titleMedium: '1.2em',
  textButton: '0.8em'
}
