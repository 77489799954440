import axios, {AxiosResponse} from 'axios';

// const {REACT_APP_API_URL} = process.env
const baseApiUrl = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : 'https://www.tramandare.it/api/'

// FIXME: ONLY FOR DEBUG
const DEBUG_DELAY = false;

const responseBody = (response: AxiosResponse) => response.data;

const instance = axios.create({
  baseURL: baseApiUrl,
  headers: {
    'Content-type': 'application/json'
  },
  timeout: 15000,
});

const api = {
  get: (url: string) => instance.get(url).then(responseBody),
  getWithType: (url: string, responseType: any) => instance.get(url, {responseType: responseType}),
  post: (url: string, body: {}) => instance.post(url, body).then(responseBody),
  put: (url: string, body: {}) => instance.put(url, body).then(responseBody),
  delete: (url: string) => instance.delete(url).then(responseBody),
};

const errorHandler = (error: any) => {
  const statusCode = error.response?.status

  // logging only errors that are not 401
  if (statusCode && statusCode !== 401) {
    console.error(error)
  }

  return Promise.reject(error)
}

// registering the custom error handler to the/ "api" axios instance
instance.interceptors.response.use(undefined, (error) => {
  return errorHandler(error)
})

instance.interceptors.request.use((config) => {
  if (DEBUG_DELAY) {
    return new Promise(resolve => setTimeout(() => resolve(config), 1000));
  }
  return config;
});

export default api;
