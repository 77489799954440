import React, {Fragment, useEffect, useState} from 'react';

import {Card, Col, Container, Row} from 'react-bootstrap';
import {trackPromise} from 'react-promise-tracker';
import {NavLink} from 'react-router-dom';

import {RegioneAPI} from 'apis/RegioneAPI';
import {HeroImage} from 'components/HeroImage';
import {ImageBlock} from 'components/ImageBlock';
import {LoadingSpinner} from 'components/LoadingSpinner';
import {Regione} from 'models/Regione';
import {StyledSubTitle, StyledTitleBig, StyledTitleMedium} from 'themes/CommonStyles';
import {Colors} from 'themes/constants';
import Utils from 'utils/Utils';

interface RegioneItemProps {
  regione: Regione
}

const RegioniItem = ({regione}: RegioneItemProps) => {
  return (
    <Card style={{margin: '10px', width: '15rem'}}>
      <Card.Img variant="top"/>
      <Card.Body>
        <Card.Title>
          <NavLink to={'/audiodoc/regione/' + regione.id}>{regione.denominazione}</NavLink>
        </Card.Title>
      </Card.Body>
    </Card>
  )
}

const RegioniList = () => {
  const [regioni, setRegioni] = useState<Regione[]>([]);

  useEffect(() => {
    const fetchData = () => {
      trackPromise(
        RegioneAPI.list().then((regioni: Regione[]) => {
          setRegioni(regioni);
        }).catch((error) => {
          Utils.connectionError();
        }), 'regioni'
      );
    };

    fetchData();
  }, []);

  return (
    <>
      <div>
        <LoadingSpinner area={'regioni'}/>

        <Row style={{textAlign: 'center'}}>
          {regioni.map((x: Regione, i: number) =>
            <RegioniItem key={i} regione={x}/>
          )}
        </Row>
      </div>
    </>
  )
}

export const Audiodoc = () => {
  return (
    <Fragment>
      <HeroImage image="/images/audiodoc.png" height={500}/>

      <ImageBlock background="/images/background-02.png" height={121}/>

      <Container fluid style={{textAlign: 'center'}}>
        <Row>
          <Col>
            <StyledTitleBig>DI QUALE PARTE D’ITALIA VUOI ASCOLTARE LE PAROLE?</StyledTitleBig>
          </Col>
        </Row>
        <Row>
          <Col>
            <StyledTitleBig>SCEGLI LA REGIONE</StyledTitleBig>
          </Col>
        </Row>
      </Container>

      <Container fluid={true} style={{marginTop: '10px', marginBottom: '10px', padding: '40px'}}>
        <Row className="justify-content-md-center">
          <Col xs={12} md={5}>
            <img src="/images/cartina.png" width={100} alt="" className="responsive"/>
          </Col>
          <Col xs={12} md={5}>
            {/* Selezione della regione */}
            <RegioniList/>
          </Col>
        </Row>
      </Container>

      <Container fluid style={{padding: '40px', backgroundColor: Colors.lightBackground}}>
        <Row className="justify-content-md-center">
          <Col xs={12} md={5}>
            <StyledTitleMedium>TRAMANDARE, UN VIAGGIO ATTRAVERSO LE MEMORIE SONORE D’ITALIA</StyledTitleMedium>
            Ogni luogo è custode dei “propri” segreti che, se venissero immortalati o se non fossero stati trasmessi,
            perderebbero il loro potere, verrebbero persi per sempre. Ma non dobbiamo temere l’oblio, Allora perché
            recuperare? Non si recupera un bel niente, ma si ascolta. </Col>
          <Col xs={12} md={5}>
            <StyledSubTitle>SI ASCOLTA CIÒ CHE LE MARGINALITÀ UMANE, LE PERIFERIE DELL’UMANITÀ CONSERVANO, PATRIMONIO
              ANCESTRALE DI FORZA E BELLEZZA RACCHIUSO IN OGNUNO DI NOI</StyledSubTitle>
          </Col>
        </Row>
      </Container>

      <ImageBlock background="/images/background-02.png" height={121}/>
    </Fragment>
  )
}
