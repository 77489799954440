import React from 'react';

import {Col, Container} from 'react-bootstrap';
import {NavLink} from 'react-router-dom';

import {ImageBlock} from 'components/ImageBlock';
import {StyledButton} from 'themes/CommonStyles';

import {Title, StyledRow, SubTitle} from './TramandareFooter.styles';

export const TramandareFooter = () => {
  return (
    <>
      <ImageBlock background="/images/background-02.png" height={121}/>

      <Container fluid={true}>
        <StyledRow className="justify-content-md-center">
          <Col xs={12} md={5}>
            <Title>TRAMANDA INSIEME A NOI!</Title>

            Tramandare è un progetto nato dalla volontà di raccogliere piccole ma grandi storie di persone comuni.
            Persone che hanno tratto lezioni e insegnamenti dalla loro vita, e che hanno scelto di condividere le loro
            storie con chiunque voglia ascoltarle.<br/>
            Anche il più complicato dei sogni, si può realizzare... insieme.<br/>
            <br/>
            <SubTitle>Ti ringraziamo per il tuo aiuto!</SubTitle>
          </Col>

          <Col xs={12} md={5}>
            <SubTitle>
              ABBIAMO SCELTO DI TRAMANDARE QUESTE STORIE PER PORTARE AVANTI NEL TEMPO LA LORO MEMORIA BLA
            </SubTitle>
            <br/>
            <NavLink to={'/sostieni'}><StyledButton>SOSTIENI TRAMANDARE</StyledButton></NavLink>
          </Col>
        </StyledRow>
      </Container>
    </>
  )
}
