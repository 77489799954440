import React from 'react';

export const ImageBlock = ({...props}: any) => {

  // const height = props.height ? props.height : '200%'
  const styles: { [key: string]: React.CSSProperties } = {
    article: {
      padding: '20px',
      height: props.height,
      backgroundRepeat: 'no-repeat',
      // backgroundPosition: 'center',
      // backgroundPosition: 'left top',
      backgroundSize: 'cover',
      // backgroundSize: 'auto 100%',
      width: '100%',
      maxWidth: '100%',
      display: 'grid',
      placeItems: 'center',
      backgroundImage: `url(${props.background})`
    },
    header: {
      fontSize: '16px',
      color: 'white',
      textAlign: 'center',
      fontFamily: 'Courier New, Courier, monospace',
    }
  }

  return (
    <React.Fragment>
      <div style={styles.article}>
        {
          props.description ?
            <div style={styles.header}>{props.description}</div>
            : ''
        }
      </div>
    </React.Fragment>
  )
}
